.css-jery6c{
    width: 290px !important
  }
  .startpopup.socialmediastyle .socialleft button {
    width: inherit;
    border-radius: 29px !important;
    height: 42px;
}
.startpopup.socialmediastyle .glider-dot {
  display: block;
  cursor: pointer;
  color: #ccc;
  border-radius: 999px;
  background: #ccc;
  width: 12px;  
  height: 12px;
  margin: 7px;
  
}
.startpopup.socialmediastyle .bluecirl button {
  height: 20px !important;
}
.startpopup.socialmediastyle .glider-btn-box.number button ,.startpopup.socialmediastyle .glider-next,  .startpopup.socialmediastyle .glider-prev{
  width: auto;
}
.startpopup.socialmediastyle .copybtnn{
  border-radius:0px !important;
}
.mainbx>div>div{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}